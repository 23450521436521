import { Grid } from '@nestoca/ui';
import { useRecoilValue } from 'recoil';

import { PROPERTY_TYPE } from 'components/dashboard-header/components/application-info';
import { useI18n } from 'providers/i18n/use-i18n';
import { getPropertySelectors } from 'store/applications';

import { CellData } from '../../applicants/components/cell-data';

export const Renewal = () => {
    const { i18n } = useI18n();
    const {
        application: { property },
        sumAssets,
        mortgageAmount,
        closingCost,
    } = useRecoilValue(getPropertySelectors);

    return (
        <>
            <Grid css={{ gridTemplateColumns: 'repeat(2, 1fr)' }}>
                <CellData
                    tx={'dashboardHeader.propertyType'}
                    value={PROPERTY_TYPE(i18n)[property.propertyType]}
                />
                <CellData
                    fieldType="money"
                    tx="Property Value"
                    value={property.purchasePrice}
                />
                <CellData
                    fieldType="money"
                    tx={'mortgageBalance'}
                    value={mortgageAmount.amount}
                />
                <CellData
                    fieldType="money"
                    tx={'closingCost'}
                    value={closingCost}
                />
                <CellData
                    fieldType="money"
                    tx={'additionalAmount'}
                    value={property.additionalFundAmount}
                />
                <CellData
                    fieldType="money"
                    tx={'totalAssets'}
                    value={sumAssets.value}
                />
            </Grid>
        </>
    );
};
