import React from 'react';

import { Flex, Typography } from '@nestoca/ui';
import cn from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { AddressEditable } from 'components/editable/address-editable';
import { Applicants } from 'components/owned-properties/section';
import { PropertyDetail } from 'components/owned-properties/section';
import styles from 'components/owned-properties/section/section.module.scss';
import { useI18n } from 'providers/i18n/use-i18n';

import type { OtherProperty, PropertyIncludedFieldName } from 'types/property';

type ToggleFn = (
    included: boolean,
    fieldName: PropertyIncludedFieldName
) => void;

type Props = {
    isCreating?: boolean;
    isEditing: boolean;
    isNewOwnedProperty?: boolean;
    applicationId: number;
    applicantId: number;
    property: OtherProperty;
    onToggle?: ToggleFn;
};

export const Sections = ({
    isCreating,
    isEditing,
    isNewOwnedProperty = false,
    applicationId,
    applicantId,
    property,
    onToggle,
}: Props) => {
    const { i18n } = useI18n();
    const { partialSavingOwnedProperties } = useFlags();

    return (
        <>
            <Applicants
                isCreating={isCreating}
                isEditing={isEditing}
                ownedProperty={property}
                applicationId={applicationId}
                applicantId={applicantId}
                isNewOwnedProperty={isNewOwnedProperty}
            />
            {(isNewOwnedProperty || (!isNewOwnedProperty && isEditing)) && (
                <Flex className={styles.section} direction="column" gap={4}>
                    <Typography weight={7} textColor="var(--color-black)">
                        {i18n._('propertyAddress')}
                    </Typography>
                    <AddressEditable
                        className={cn(
                            styles['grid'],
                            styles['grid--with-divider'],
                            {
                                [styles['grid--auto-fit']]: isCreating,
                            }
                        )}
                        required
                        isFound
                        isEditing={isEditing}
                        address={property.address}
                        namePrefix="address."
                        usesPartialSaving={partialSavingOwnedProperties}
                        gridGap="var(--spacing-4)"
                    />
                </Flex>
            )}
            <Flex direction="column" gap={4}>
                <Typography weight={7} textColor="var(--color-black)">
                    {i18n._('propertyDetails')}
                </Typography>
                <PropertyDetail
                    isCreating={isCreating}
                    isEditing={isEditing}
                    property={property}
                    applicationId={applicationId}
                    onToggle={onToggle}
                />
            </Flex>
        </>
    );
};
