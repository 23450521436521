import { Divider, Grid } from '@nestoca/ui';
import { useRecoilValue } from 'recoil';

import {
    INTEREST_RATE_TYPE,
    MORTGAGE_TYPE,
    PROPERTY_TYPE,
    TERM_TYPE,
} from 'components/dashboard-header/components/application-info';
import { LENDER } from 'constants/appConstants';
import { useI18n } from 'providers/i18n/use-i18n';
import { getPropertySelectors } from 'store/applications';
import { formatDate } from 'utils/property';

import { CellData } from '../../applicants/components/cell-data';

export const Refinance = () => {
    const { i18n } = useI18n();
    const {
        application: { property },
        sumAssets,
        financingAmount,
        mainApplicant,
        mortgageAmount,
        closingCost,
    } = useRecoilValue(getPropertySelectors);

    return (
        <>
            <Grid css={{ gridTemplateColumns: 'repeat(2, 1fr)' }}>
                <CellData
                    tx={'dashboardHeader.propertyType'}
                    value={PROPERTY_TYPE(i18n)[property.propertyType]}
                />
                <CellData
                    fieldType="money"
                    tx="Property Value"
                    value={property.purchasePrice}
                />
                <CellData
                    fieldType="money"
                    tx={'mortgageBalance'}
                    value={mortgageAmount.amount}
                />
                <CellData
                    fieldType="money"
                    tx={'closingCost'}
                    value={closingCost}
                />
                <CellData
                    fieldType="money"
                    tx={'additionalAmount'}
                    value={property.additionalFundAmount}
                />
                <CellData
                    fieldType="money"
                    tx={'totalAssets'}
                    value={sumAssets.value}
                />
                <CellData
                    fieldType="money"
                    tx={'financingAmount'}
                    value={financingAmount}
                    variant="primary"
                />
            </Grid>
            <Divider />
            <Grid css={{ gridTemplateColumns: 'repeat(2, 1fr)' }}>
                <CellData
                    tx={'dashboardHeader.propertyFound'}
                    value={property.isFound ? i18n._('yes') : i18n._('no')}
                />
                <CellData
                    tx={'dashboardHeader.firstTimeBuyer'}
                    value={
                        mainApplicant.firstTimeHomeBuyer
                            ? i18n._('yes')
                            : i18n._('no')
                    }
                />
                <CellData
                    tx={'mortgageType'}
                    value={
                        MORTGAGE_TYPE(i18n)[
                            property?.mortgages[0]?.mortgageType
                        ]
                    }
                />
                <CellData
                    tx={'rateType'}
                    value={
                        INTEREST_RATE_TYPE(i18n)[
                            property?.mortgages[0]?.interestRateType
                        ]
                    }
                />
                <CellData
                    tx={'termType'}
                    value={TERM_TYPE(i18n)[property?.mortgages[0]?.termType]}
                />
                <CellData
                    tx={'originallyInsured'}
                    value={
                        property?.mortgages[0]?.originallyInsured
                            ? i18n._('yes')
                            : i18n._('no')
                    }
                />
                <CellData
                    tx={'maturityDate'}
                    value={formatDate(
                        property?.mortgages[0]?.maturityDate,
                        i18n.locale
                    )}
                />

                <CellData
                    tx={'lender'}
                    value={LENDER(i18n)[property?.mortgages[0]?.lender]}
                />
            </Grid>
        </>
    );
};
