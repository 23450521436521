import React from 'react';

import { Trans } from '@lingui/react';
import css from '@styled-system/css';
import { nanoid } from 'nanoid';
import { useRecoilValue } from 'recoil';

import { CheckboxComponent, Label } from 'components/forms';
import { Spinner } from 'components/spinner';
import { includedInLoadingState } from 'store/ui';

import type { IncomeIncludedFieldName } from 'types/applicant';
import type { PropertyIncludedFieldName } from 'types/property';

export const IncludeCheckbox = ({
    checked,
    fieldName,
    gridArea,
    disabled = false,
    handleToggle,
    includeTitle,
    id,
    ...rest
}: {
    checked: boolean;
    gridArea?: string;
    disabled?: boolean;
    fieldName: IncomeIncludedFieldName | PropertyIncludedFieldName;
    handleToggle?: (
        checked: boolean,
        fieldName: IncomeIncludedFieldName | PropertyIncludedFieldName
    ) => void;
    includeTitle: string;
    id?: string;
}) => {
    const [itemId] = React.useState(id || nanoid);
    const loadingState = useRecoilValue(includedInLoadingState);

    const disableCheckbox =
        disabled ||
        (!loadingState.includes(fieldName) && loadingState.length > 0);

    return (
        <Label
            gridArea={gridArea}
            name={`checkbox-taxes`}
            css={css({
                fontSize: 0,
                color: disableCheckbox ? '#C9CACF' : 'gray-1',
                alignItems: 'center',
            })}
            variant="primary"
            htmlFor={itemId}
            {...rest}
        >
            {loadingState.includes(fieldName) ? (
                <Spinner size={20} css={{ marginRight: '12px' }} />
            ) : (
                <CheckboxComponent
                    id={itemId}
                    // Will make the checkbox more responsive but I think we should add a loading indicator
                    // defaultChecked={liability?.included}
                    checked={checked && !disabled}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleToggle &&
                        handleToggle(event.currentTarget.checked, fieldName)
                    }
                    disabled={disableCheckbox}
                    css={{
                        cursor: disableCheckbox ? 'auto' : 'pointer',
                    }}
                />
            )}
            <Trans id={includeTitle} />
        </Label>
    );
};
