import React from 'react';

import { Trans } from '@lingui/react';
import css from '@styled-system/css';
import Link from 'next/link';
import { useRecoilValue } from 'recoil';
import { Flex } from 'reflexbox/styled-components';
import styled from 'styled-components';

import { Frame } from 'components/card/frame';
import { DividerVertical } from 'components/divider-vertical';
import { ProductSelectButton } from 'components/rates/product-select-button';
import { Text } from 'components/text/text';
import { useI18n } from 'providers/i18n/use-i18n';
import { currentApplicationIdState } from 'store/applications';

import { formatDateTime } from '../../utils';

import { InvestorAccent } from './investor-accent';
import { LenderHeader } from './lender-header';
import { LenderTable } from './lender-table';

import type { Props as FrameProps } from 'components/card/frame';
import type { Product } from 'types/rates';

export const ButtonContainer = styled(Flex)`
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    border: none;
    font-size: bold;
    border-radius: 18px;
`;

export const ActionButton = styled.a`
    transition: all 250ms ease;
    cursor: pointer;
    padding: 4px;

    font-size: 12px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    line-height: 24px;
    color: #212243;

    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;

    :hover {
        background-color: #f0f0f0;
    }

    :first-of-type {
        border-top-left-radius: 18px;
        border-bottom-left-radius: 18px;
    }

    :last-of-type {
        border-top-right-radius: 18px;
        border-bottom-right-radius: 18px;
    }
`;

type Props = {
    product: Product;
    showSelectProduct?: boolean;
};

export const RateTile = ({
    product,
    showSelectProduct = true,
    ...rest
}: Props & FrameProps) => {
    const { i18n } = useI18n();
    const currentApplicationId = useRecoilValue(currentApplicationIdState);
    return (
        <Frame
            {...rest}
            css={css({
                position: 'relative',
            })}
        >
            <InvestorAccent product={product} />
            <LenderHeader
                lenderId={product.lenderId}
                productName={product.name}
                productId={product.externalId}
            />
            <LenderTable
                term={product.term}
                type={product.type}
                ltvMin={product.ltvMin}
                ltvMax={product.ltvMax}
                rate={product.bestRate}
                rateHold={product.rateHold}
                creditScoreMin={product.creditScoreMin}
                mortgageAmountMin={product.mortgageAmountMin}
            />
            <ButtonContainer marginTop="20px">
                <Link href={`rates/${product.id}`} passHref legacyBehavior>
                    <ActionButton>
                        <Trans id="Learn more" />
                    </ActionButton>
                </Link>
                {/* THIS IS A TEMPORARY FIX
                 * For IG, we do not have a PDF. We will need to determine where we want to handle this.
                 * If we assume that it's possible to not have a PDF for this tenant, this should be hidden behind a feature flag.
                 * If a PDF is required, the product importer should take care of this validation.
                 * @see https://nestoca.atlassian.net/browse/SEAL-956
                 */}
                {product.presentationPdfUrlsByLanguage[i18n.locale] && (
                    <>
                        <DividerVertical />
                        <Link
                            href={
                                product.presentationPdfUrlsByLanguage[
                                    i18n.locale
                                ]
                            }
                            passHref
                            legacyBehavior
                        >
                            <ActionButton target="_blank">
                                <Trans id="Download PDF" />
                            </ActionButton>
                        </Link>
                    </>
                )}
            </ButtonContainer>

            {showSelectProduct && (
                <Flex mt={2} width="100%">
                    <ProductSelectButton
                        applicationId={currentApplicationId}
                        investorId={product?.investorId}
                        lenderId={product?.lenderId}
                        productId={product?.id}
                        productRate={product?.rate}
                        regionCode={product?.regionCode}
                        productVersion={product?.version}
                        productName={product?.name}
                        insurable={product?.insurable}
                    />
                </Flex>
            )}
            <Flex
                css={css({
                    paddingTop: '20px',
                    flexDirection: 'column',
                })}
            >
                <Text
                    css={css({ fontSize: 0, color: 'gray-2', marginBottom: 1 })}
                    tx="Created"
                >
                    {`: ${formatDateTime(new Date(product.created))}`}
                </Text>
                <Text
                    css={css({ fontSize: 0, color: 'gray-2' })}
                    tx="Last updated"
                >
                    {`: ${formatDateTime(new Date(product.updated))}`}
                </Text>
            </Flex>
        </Frame>
    );
};
