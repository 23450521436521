import React from 'react';

import { Flex, Grid, Typography } from '@nestoca/ui';
import cn from 'classnames';

import { EditableCell } from 'components/editable';
import { CheckboxToggleFn } from 'components/owned-properties/section';
import { IncludeCheckbox } from 'components/scenarios/components/checkbox';
import { FREQUENCY } from 'constants/appConstants';
import { ProblemType } from 'constants/problem';
import { useI18n } from 'providers/i18n/use-i18n';
import { useEditingRights } from 'store/rights';
import { CoOwnershipFees, OtherProperty } from 'types/property';
import { formatMoney, maxTwoDecimal } from 'utils';
import { compareForId } from 'utils/validations/comparators';

import styles from './property-detail-section.module.scss';

type Props = {
    isCreating?: boolean;
    isEditing: boolean;
    condoFees: Partial<CoOwnershipFees>;
    property: OtherProperty;
    onCheckboxToggle: CheckboxToggleFn;
};

export const CondoFees = ({
    isCreating,
    isEditing,
    condoFees,
    property,
    onCheckboxToggle,
}: Props) => {
    const hasEditingRights = useEditingRights();

    const { i18n } = useI18n();

    const { condoFeesIncluded } = property;

    return (
        <Flex
            className={styles['property-detail-section']}
            direction="column"
            gap={4}
        >
            <Typography size={0} weight={6} textColor="var(--color-black)">
                {i18n._('condoFeesLabel')}
            </Typography>
            <Grid
                className={cn(styles['grid'], {
                    [styles['grid--auto-fit']]: isCreating,
                })}
                gap={4}
            >
                <EditableCell
                    name="condoFees.amount"
                    label="amount"
                    fieldType="money"
                    value={formatMoney(condoFees?.amount)}
                    isEditing={isEditing}
                    isFieldInvalidCompareFn={compareForId(property)}
                    problemType={ProblemType.properties}
                />

                <EditableCell
                    name="condoFees.frequency"
                    label="frequency"
                    fieldType="select"
                    options={FREQUENCY(i18n)}
                    value={condoFees?.frequency}
                    isEditing={isEditing}
                    isClearable
                    isFieldInvalidCompareFn={compareForId(property)}
                    problemType={ProblemType.properties}
                />
                <EditableCell
                    required
                    name="condoFees.ratio"
                    label="Offset"
                    fieldType="percentage"
                    value={maxTwoDecimal(condoFees?.ratio) || 50}
                    isEditing={isEditing}
                    isFieldInvalidCompareFn={compareForId(property)}
                    problemType={ProblemType.properties}
                />
                {!isEditing && hasEditingRights && (
                    <IncludeCheckbox
                        fieldName="condoFeesIncluded"
                        includeTitle="includeInTDS"
                        checked={condoFeesIncluded}
                        handleToggle={onCheckboxToggle}
                    />
                )}
            </Grid>
        </Flex>
    );
};
