import React from 'react';

import { Flex, Grid, Typography } from '@nestoca/ui';
import cn from 'classnames';

import { EditableCell } from 'components/editable';
import { CheckboxToggleFn } from 'components/owned-properties/section';
import { IncludeCheckbox } from 'components/scenarios/components/checkbox';
import { PAYMENT_FREQUENCY_OPTIONS } from 'constants/appConstants';
import { ProblemType } from 'constants/problem';
import { useI18n } from 'providers/i18n/use-i18n';
import { useEditingRights } from 'store/rights';
import { OtherProperty } from 'types/property';
import { compareForId } from 'utils/validations/comparators';

import styles from './property-detail-section.module.scss';

type Props = {
    isCreating?: boolean;
    isEditing: boolean;
    isCondo: boolean;
    isHeatingRequired: boolean;
    heatingIncluded?: boolean;
    property: OtherProperty;
    onCheckboxToggle: CheckboxToggleFn;
};

export const HeatingFees = ({
    isCreating,
    isEditing,
    isCondo,
    isHeatingRequired,
    heatingIncluded,
    property,
    onCheckboxToggle,
}: Props) => {
    const hasEditingRights = useEditingRights();

    const { i18n } = useI18n();

    const { heatingCost, heatingCostIncluded } = property;

    return (
        <Flex
            className={styles['property-detail-section']}
            direction="column"
            gap={4}
        >
            <Typography size={0} weight={6} textColor="var(--color-black)">
                {i18n._('heatingFees')}
            </Typography>
            <Grid
                className={cn(styles['grid'], {
                    [styles['grid--auto-fit']]: isCreating,
                })}
                gap={4}
            >
                {isCondo && (
                    <EditableCell
                        required
                        name="condoFees.heatingIncluded"
                        label="heatingIncluded"
                        fieldType="boolean"
                        value={heatingIncluded}
                        isEditing={isEditing}
                        isFieldInvalidCompareFn={compareForId(property)}
                        problemType={ProblemType.properties}
                    />
                )}
                {(!heatingIncluded ||
                    //@ts-ignore
                    heatingIncluded === 'false') && (
                    <>
                        <EditableCell
                            required={isHeatingRequired}
                            name="heatingCost.amount"
                            label="amount"
                            fieldType="money"
                            value={heatingCost?.amount}
                            isEditing={isEditing}
                            isFieldInvalidCompareFn={compareForId(property)}
                            problemType={ProblemType.properties}
                        />
                        <EditableCell
                            required={isHeatingRequired}
                            name="heatingCost.frequency"
                            label="frequency"
                            fieldType="select"
                            options={PAYMENT_FREQUENCY_OPTIONS(i18n)}
                            value={heatingCost?.frequency}
                            isEditing={isEditing}
                            isClearable
                            isFieldInvalidCompareFn={compareForId(property)}
                            problemType={ProblemType.properties}
                        />
                    </>
                )}
                {!isEditing && hasEditingRights && (
                    <IncludeCheckbox
                        fieldName="heatingCostIncluded"
                        includeTitle="includeInTDS"
                        checked={heatingCostIncluded}
                        handleToggle={onCheckboxToggle}
                    />
                )}
            </Grid>
        </Flex>
    );
};
