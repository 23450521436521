import React from 'react';

import intlFormat from 'date-fns/intlFormat';
import { useRecoilValue } from 'recoil';

import { LabelWithValue } from 'components/dashboard-header/components/label-with-value';
import { Grid } from 'components/grid/grid';
import { LENDER } from 'constants/appConstants';
import { useI18n } from 'providers/i18n/use-i18n';
import { getMainApplicant, getSubjectProperty } from 'store/applications';
import { applicationDocumentsCounts } from 'store/documents';
import { DocumentStep } from 'types/documents';
import { formatPercentage, isEmpty } from 'utils';
import { getApplicationMainType } from 'utils/application-type';

import type { I18n } from '@lingui/core';

export const PROPERTY_TYPE = (i18n: I18n) => ({
    DETACHED: i18n._('propertyType.detached'),
    SEMI_DETACHED: i18n._('propertyType.semidetached'),
    DUPLEX_DETACHED: i18n._('propertyType.duplex_detached'),
    DUPLEX_SEMI_DETACHED: i18n._('propertyType.duplex_semi_detached'),
    ROW_HOUSING: i18n._('propertyType.row_housing'),
    APARTMENT_LOW_RISE: i18n._('propertyType.apartment_low_rise'),
    APARTMENT_HIGH_RISE: i18n._('propertyType.apartment_hi_rise'),
    MOBILE: i18n._('propertyType.mobile'),
    TRI_PLEX_DETACHED: i18n._('propertyType.tri_plex_detached'),
    TRI_PLEX_SEMI_DETACHED: i18n._('propertyType.tri_plex_semi_detached'),
    STACKED: i18n._('propertyType.stacked'),
    MODULAR_HOME_DETACHED: i18n._('propertyType.modular_home_detached'),
    MODULAR_HOME_SEMI_DETACHED: i18n._(
        'propertyType.modular_home_semi_detached'
    ),
    FOUR_PLEX_DETACHED: i18n._('propertyType.four_plex_detached'),
    FOUR_PLEX_SEMI_DETACHED: i18n._('propertyType.four_plex_semi_detached'),
    OTHER: i18n._('propertyType.other'),
});

export const PROPERTY_PURPOSE = (i18n: I18n) => ({
    OWNER_OCCUPIED: i18n._(`propertyPurpose.ownerOccupied`),
    ANY_RENTAL: i18n._(`propertyPurpose.anyRental`),
    RENTAL_1_UNIT: i18n._(`propertyPurpose.rental1Unit`),
    'RENTAL_2-4_UNITS': i18n._(`propertyPurpose.rental2-4Units`),
});

export const TERM_TYPE = (i18n: I18n) => ({
    OPEN: i18n._(`termType.open`),
    CLOSED: i18n._(`termType.closed`),
    CONVERTABLE: i18n._(`termType.convertable`),
});

export const INTEREST_RATE_TYPE = (i18n: I18n) => ({
    FIXED: i18n._(`interestRateType.fixed`),
    VARIABLE: i18n._(`interestRateType.variable`),
    ADJUSTABLE: i18n._(`interestRateType.adjustable`),
    CAPPED_VARIABLE: i18n._(`interestRateType.cappedVariable`),
});

export const MORTGAGE_TYPE = (i18n: I18n) => ({
    STANDARD: i18n._(`mortgageType.standard`),
    HELOC: i18n._(`mortgageType.heloc`),
});

const getProductState = (product, i18n) => {
    if (isEmpty(product)) return i18n._('unreached');

    if (product.notSureYet) {
        return i18n._('completedNotSureYet');
    }

    return i18n._('completed');
};

export const ApplicationInfo = ({
    currentApplicationId,
    application,
}: {
    currentApplicationId: number;
    application: any;
}) => {
    const { isNewApplication } = getApplicationMainType(application.type);

    const property = useRecoilValue(getSubjectProperty(currentApplicationId));

    const mainApplicant = useRecoilValue(
        getMainApplicant(currentApplicationId)
    );

    const documentCounts = useRecoilValue(
        applicationDocumentsCounts({
            applicationId: currentApplicationId,
            step: DocumentStep.All,
        })
    );
    const { i18n } = useI18n();

    return (
        <Grid
            gridAutoRows="auto"
            gridTemplateColumns={'1fr 1fr'}
            gridTemplateAreas={`
                "applicantDetails productSelection"
                "creditScore propertyPurpose"
                "closingDate acceptanceDate"
                "firstTimeBuyer documents"
                ${
                    !isNewApplication
                        ? `
                            "interestRateType mortgageType"
                            "ltv originallyInsured"
                            "termType lender"
                            "maturityDate timeLeft"`
                        : ''
                }
            `}
        >
            <LabelWithValue
                gridArea="applicantDetails"
                label={'dashboardHeader.propertyFound'}
                value={property.isFound ? i18n._('yes') : i18n._('no')}
                showBorderRight
            />

            <LabelWithValue
                gridArea="creditScore"
                label={'dashboardHeader.propertyType'}
                value={PROPERTY_TYPE(i18n)[property.propertyType]}
                showBorderRight
            />

            <LabelWithValue
                gridArea="closingDate"
                label={'dashboardHeader.closingDate'}
                value={
                    application.closingDate
                        ? intlFormat(
                              new Date(
                                  `${application.closingDate}T01:00:00-05:00`
                              ),
                              {
                                  weekday: 'long',
                                  year: 'numeric',
                                  month: 'short',
                                  day: '2-digit',
                              },
                              { locale: i18n.locale }
                          )
                        : '-'
                }
                showBorderRight
            />

            <LabelWithValue
                gridArea="firstTimeBuyer"
                label={'dashboardHeader.firstTimeBuyer'}
                value={
                    mainApplicant.firstTimeHomeBuyer
                        ? i18n._('yes')
                        : i18n._('no')
                }
                showBorderRight
            />

            <LabelWithValue
                gridArea="productSelection"
                label={'dashboardHeader.productSelection'}
                value={getProductState(application.product, i18n)}
            />

            <LabelWithValue
                gridArea="propertyPurpose"
                label={'dashboardHeader.propertyPurpose'}
                value={PROPERTY_PURPOSE(i18n)[property.purpose]}
            />
            <LabelWithValue
                gridArea="acceptanceDate"
                label={'acceptanceDate'}
                value={
                    property.acceptanceDate
                        ? intlFormat(
                              new Date(
                                  `${property.acceptanceDate}T01:00:00-05:00`
                              ),
                              {
                                  weekday: 'long',
                                  year: 'numeric',
                                  month: 'short',
                                  day: '2-digit',
                              },
                              { locale: i18n.locale }
                          )
                        : '-'
                }
            />

            <LabelWithValue
                gridArea="documents"
                label={'dashboardHeader.documents'}
                value={`${documentCounts.receivedCount} / ${documentCounts.totalCount}`}
            />

            {!isNewApplication && (
                <>
                    <LabelWithValue
                        gridArea="interestRateType"
                        label={'rateType'}
                        value={
                            INTEREST_RATE_TYPE(i18n)[
                                property?.mortgages[0]?.interestRateType
                            ]
                        }
                        showBorderRight
                    />
                    <LabelWithValue
                        gridArea="mortgageType"
                        label={'mortgageType'}
                        value={
                            MORTGAGE_TYPE(i18n)[
                                property?.mortgages[0]?.mortgageType
                            ]
                        }
                    />

                    <LabelWithValue
                        gridArea="ltv"
                        label={'ltv'}
                        value={formatPercentage(
                            (property?.mortgages[0]?.balance /
                                property.estimatedPropertyValue) *
                                100
                        )}
                        showBorderRight
                    />
                    <LabelWithValue
                        gridArea="originallyInsured"
                        label={'originallyInsured'}
                        value={
                            property?.mortgages[0]?.originallyInsured
                                ? i18n._('yes')
                                : i18n._('no')
                        }
                    />

                    <LabelWithValue
                        gridArea="termType"
                        label={'termType'}
                        value={
                            TERM_TYPE(i18n)[property?.mortgages[0]?.termType]
                        }
                        showBorderRight
                    />
                    <LabelWithValue
                        gridArea="lender"
                        label={'lender'}
                        value={LENDER(i18n)[property?.mortgages[0]?.lender]}
                    />

                    <LabelWithValue
                        gridArea="maturityDate"
                        label={'maturityDate'}
                        value={
                            property?.mortgages[0]?.maturityDate
                                ? intlFormat(
                                      new Date(
                                          `${property?.mortgages[0]?.maturityDate}T01:00:00-05:00`
                                      ),
                                      {
                                          weekday: 'long',
                                          year: 'numeric',
                                          month: 'short',
                                          day: '2-digit',
                                      },
                                      { locale: i18n.locale }
                                  )
                                : '-'
                        }
                        showBorderRight
                    />

                    <LabelWithValue
                        gridArea="timeLeft"
                        label={'timeLeftOnTerm'}
                        value={
                            property?.mortgages[0]?.maturityDate
                                ? i18n._({
                                      id: 'daysLeft',
                                      values: {
                                          days: Math.ceil(
                                              Math.abs(
                                                  new Date(
                                                      `${property?.mortgages[0]?.maturityDate}T01:00:00-05:00`
                                                  ).getTime() -
                                                      new Date().getTime()
                                              ) /
                                                  (1000 * 3600 * 24)
                                          ),
                                      },
                                  })
                                : '-'
                        }
                    />
                </>
            )}
        </Grid>
    );
};
