import { SupportedTenant, TenantSlug } from '@nestoca/multi-tenant';

import { CoApplicantSearchResult } from 'types/co-applicant-search';

/**
 * This function is used to get the default account id selected
 * @param accounts Accounts
 * @returns The account id selected
 */
export const getDefaultAccountIdSelected = (
    accounts: CoApplicantSearchResult[]
) => {
    // If there is only one result, it's selected by default
    if (accounts.length === 1) {
        return `${accounts[0].id}`;
    }
    return '';
};

/**
 * This function is used to get the account selected
 * @param accountIdSelected The account id selected
 * @param accounts Accounts
 * @returns The account selected
 */
export const getAccountSelected = (
    accountIdSelected: string,
    accounts: CoApplicantSearchResult[]
) => accounts.find(({ id }) => id === +accountIdSelected) || null;

/**
 * Checks if the provided email is already registered among the given accounts for a specific tenant
 * @param email  Email to check for existence
 * @param accounts Accounts to check for the email existence
 * @param tenant Specific tenant for which the existence is checked
 * @returns True if the email already exists for the specified tenant, otherwise false
 */
export const isEmailAlreadyRegistered = (
    email: string,
    accounts: CoApplicantSearchResult[],
    tenant: TenantSlug
) => {
    if (!email) return false;

    // We only care about the existence of the email for the nesto tenant
    const emailAlreadyRegisteredByTenant: Record<SupportedTenant, boolean> = {
        nesto: accounts.some((account) => account.email === email),
        ig: false,
        'ig-dev': false,
        cmls: false,
    };

    return emailAlreadyRegisteredByTenant[tenant];
};
