import { useRecoilValueLoadable } from 'recoil';

import { Grid } from 'components/grid/grid';
import {
    INSURANCE,
    PROPERTY_PURPOSE,
    RATE_TYPES,
    TERM,
} from 'constants/appConstants';
import { LENDER_INFO_OPTIONS_BY_ID } from 'constants/lenderConstants';
import { useI18n } from 'providers/i18n/use-i18n';
import { selectedApplication } from 'store/applications';
import { formatPercentage } from 'utils';

import { CellData } from './applicants/components/cell-data';

import type { Application } from 'types/application';

export const SelectedProduct = () => {
    const { state, contents } = useRecoilValueLoadable(selectedApplication);
    const { i18n } = useI18n();

    if (state !== 'hasValue') {
        return null;
    }

    const application: Application = contents as Application;

    return (
        <Grid
            gridTemplateColumns="repeat(3, 1fr)"
            gridAutoFlow="row"
            gridGap={10}
        >
            <CellData
                fieldType="readonly"
                tx={'amortization'}
                value={
                    application?.product?.amortizationMax ||
                    application?.product?.amortization
                }
            />
            <CellData
                fieldType="readonly"
                tx={'bestRate'}
                value={
                    application?.product?.bestRate > 0 &&
                    formatPercentage(application?.product?.bestRate)
                }
                variant="primary"
            />
            <CellData
                fieldType="readonly"
                tx={'rate'}
                value={
                    application?.product?.rate > 0 &&
                    formatPercentage(application?.product?.rate)
                }
            />
            <CellData
                fieldType="readonly"
                tx={'insurance'}
                value={application?.product?.insurance}
                options={INSURANCE(i18n)}
            />
            <CellData
                fieldType="select"
                tx={'lender'}
                value={application?.product?.lenderId}
                options={LENDER_INFO_OPTIONS_BY_ID(i18n)}
                variant="primary"
            />
            <CellData
                fieldType="boolean"
                tx={'notSureYet'}
                value={application?.product?.notSureYet}
            />
            <CellData
                fieldType="readonly"
                tx={'productId'}
                value={application?.product?.productId}
            />
            <CellData
                fieldType="select"
                tx={'purpose'}
                value={application?.product?.purpose}
                options={PROPERTY_PURPOSE(i18n)}
                variant="primary"
            />
            <CellData
                fieldType="select"
                tx={'term'}
                value={application?.product?.term}
                options={TERM(i18n)}
            />
            <CellData
                fieldType="select"
                tx={'type'}
                value={application?.product?.type}
                options={RATE_TYPES(i18n)}
            />
        </Grid>
    );
};
